/* eslint-disable no-unused-vars */
import {
  cash,
  docsLoader,
  feedLoader,
  magazineLoader,
  managersLoader,
  page,
  scrolling,
  section
} from 'main.js'
import $ from 'jquery'
import PerfectScrollbar from 'perfect-scrollbar'

$(document).on('click', 'a.section', function () {
  if (!$(this).is('[data-channel]')) {
    if (location.href.search('channel.html') < 0) {
      cash = {}
      page = 0
      section = $(this).attr('data-section')
      $('.feed').empty()
      $('.side-menu .section.active').removeClass('active')
      $(this).removeClass('section').addClass('loading active')
      if (section === 'magazine') {
        magazineLoader()
      } else if (section === 'managers') {
        $('.side-menu .loading').removeClass('loading').addClass('section')
        managersLoader()
      } else if (section === 'docs') {
        docsLoader()
      } else {
        feedLoader(0)
      }
    } else {
      location.href = `/?section=${$(this).attr('data-section')}`
    }
  }
  $('.sidebar').removeClass('open')
  $('.sidebar').removeClass('ps--active-y')
})

const ps = new
PerfectScrollbar('.sidebar', {
  wheelSpeed: 2,
  wheelPropagation: true,
  minScrollbarLength: 20
})

$(document).on('scroll', scrolling)


